<template>
    <div v-if="visible" class="special-requests">
      <!-- Show add button only if no existing special request -->
      <div v-if="!specialRequest" class="special-request-button-container">
        <button @click="addSpecialRequest" class="special-request-button">
          + {{ $t('addSpecialRequest') || 'Add Special Request' }}
        </button>
        <p class="special-request-instructions">{{ $t('specialRequestInstructions') }}</p>
      </div>
  
      <!-- Show existing special request with edit option -->
      <div v-else class="existing-special-request">
        <div class="special-request-header">
          <span class="special-request-label">{{ $t('specialInstructions') }}</span>
          <button @click="addSpecialRequest" class="edit-button">
            <span class="edit-icon">✎</span>
            {{ $t('edit') }}
          </button>
        </div>
        <div class="special-request-content">
          {{ specialRequest }}
        </div>
      </div>
  
      <!-- Modal component -->
      <GetText 
        v-if="isModalOpen" 
        :title="$t('specialInstructions')" 
        :instructions="$t('specialRequestInstructions')"
        :isOpen="isModalOpen" 
        :char-limit="charLimit"
        :initial-text="specialRequest"
        @apply="setSpecialRequest" 
        @close="isModalOpen = false"
        :button-text="$t('submitButtonText')" 
      />
    </div>
  </template>
  
  <script>
  import GetText from './../../global/GetTextModal';
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'SpecialRequest',
    components: { GetText },
    props: {
      visible: {
        type: Boolean,
        required: true
      },
      specialRequestInstructions: {
        type: String,
        required: false,
        default: ''
      },
      initialSpecialRequest: {
        type: String,
        required: false,
        default: ''
      },
      charLimit: {
        type: Number,
        required: false,
        default: 32
      }
    },
    data() {
      return {
        specialRequest: '',
        isModalOpen: false,
      };
    },
    created() {
      this.specialRequest = this.initialSpecialRequest;
    },
    computed: {
      ...mapGetters(["isMobile"]),
      charLimitMessage() {
        return this.charLimit ? `${this.specialRequest.length}/${this.charLimit}` : '';
      }
    },
    watch: {
      initialSpecialRequest: {
        immediate: true,
        handler(newVal) {
          this.specialRequest = newVal;
        }
      },
      specialRequest(newVal) {
        const emojiFreeText = newVal.replace(/[\u{1F600}-\u{1F64F}]/gu, ''); // Remove emojis
        if (emojiFreeText !== newVal) {
          this.specialRequest = emojiFreeText;
        }
        this.$emit('update:specialRequest', emojiFreeText);
      }
    },
    methods: {
      addSpecialRequest() {
        this.isModalOpen = true;
      },
      openModal() {
        if (this.isMobile) {
          this.isModalOpen = true;
        }
      },
      setSpecialRequest(text) {
        this.specialRequest = text;
        this.isModalOpen = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .special-request-button-container {
    margin-top: 1.5rem;
  }
  
  .special-request-button {
    font-family: Nunito Sans;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 2.21625rem;
    text-align: left;
    color: var(--brand-color);
    text-decoration-skip-ink: none;
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .wizard-step-content .special-requests {
    border-top: none;
  }
  
  .special-requests textarea {
    width: 100%;
    height: 12.8751rem;
    padding: 0.5rem;
    border: 0.125rem solid var(--grey-outline);
    border-radius: var(--border-radius-xs);
    font-size: 2rem;
  }
  
  .special-requests p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--grey-medium);
  }
  
  .existing-special-request {
    background: var(--grey-light);
    border-radius: var(--border-radius-md);
    padding: 1.5rem;
    margin-top: 1.5rem;
  }
  
  .special-request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
  }
  
  .special-request-label {
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--grey-dark);
  }
  
  .edit-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--brand-color);
    font-weight: 600;
    font-size: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: var(--border-radius-xs);
  }
  
  .edit-button:hover {
    background: var(--grey-outline);
  }
  
  .edit-icon {
    font-size: 1.125rem;
  }
  
  .special-request-content {
    font-size: 1.125rem;
    line-height: 1.5;
    color: var(--grey-medium);
    white-space: pre-wrap;
  }
  </style>
  