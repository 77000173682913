<template>
    <div v-if="visible" :class="['screensaver', fadeClass]" @click="handleClick">
        <div v-if="backgroundType === 'video'" class="background-video-container">
            <video v-if="videoSource" ref="video" @canplaythrough="onVideoLoaded" v-show="videoLoaded" preload="auto"
                autoplay loop muted class="background-video">
                <source :src="videoSource" type="video/mp4" />
                <source :src="videoSource" type="video/webm" />
                <source :src="videoSource" type="video/ogg" />
                Your browser does not support the video tag.
            </video>
        </div>
        <template v-else-if="backgroundType === 'particles'">
            <ParticlesComponent />
        </template>
        <template v-else-if="backgroundType === 'slideshow'">
            <div class="background-slideshow">
                <div class="progress-indicator">
                    <div
                        v-for="(image, index) in normalizedSlideshow"
                        :key="index"
                        :class="['progress-dot', { active: currentSlideIndex === index }]"
                    ></div>
                </div>
                <img v-for="(image, index) in normalizedSlideshow" :key="index" :src="image.url" :class="[
                    'slideshow-image',
                    { active: currentSlideIndex === index },
                    `transition-${showTransitionEffect}`
                ]" />
            </div>
        </template>
        <div class="screensaver-content justify-content-center" v-if="showText">
            <LocationLogo v-if="config?.logo?.url" :name="config?.name || ''" class="screensaver-logo"
                :logo="config.logo.url" :shape="config.logo.shape || 'circle'" />
            <p v-if="orderTypes.length === 1">{{ orderTypes[0].name }}</p>
            <p class="order-here order" style="animation-delay: 0.5s;">{{ startFirstPart }}</p>
            <p class="order-here here" style="animation-delay: 0.75s;">{{ startSecondPart }}</p>
            <p class="tap-screen" style="animation-delay: 1s;">{{ startOrderSubtext }}</p>
        </div>
    </div>
</template>

<script>
import ParticlesComponent from '../global/ParticlesComponent.vue';
import LocationLogo from '../global/LocationLogo.vue';

export default {
    components: {
        ParticlesComponent,
        LocationLogo
    },
    emits: ['isVisible', 'selectSingleOrderType', 'showOrderTypes'],
    props: {
        orderStarted: { type: Boolean, required: true },
        startOrderText: { type: String, default: 'Order Here' },
        startOrderSubtext: { type: String, default: 'Tap screen to get started' },
        showText: { type: Boolean, default: true },
        enabled: { type: Boolean, required: true },
        orderTypes: { type: Array, default: () => [] },
        backgroundType: { type: String, default: 'video' },
        videoSource: { type: String, default: null },
        primaryColor: { type: String, default: '#000' },
        idleTimeSeconds: { type: Number, default: 15 }, // Changed default to 15 seconds
        slideshowImages: {
            type: Array,
            default: () => [],
            validator: (value) => {
                return value.every(item =>
                    typeof item === 'string' ||
                    (typeof item === 'object' && 'url' in item && 'duration' in item)
                );
            }
        },
        previewMode: {
            type: Boolean,
            default: false
        },
        slideshowInterval: {
            type: Number,
            default: 5000
        },
        transitionEffect: {
            type: String,
            default: 'fade',
            validator: (value) => [
                'fade',
                'zoom',
                'slide-left',
                'slide-up',
                'ken-burns',
                'spiral',
                'blinds',
                'morph',
                'glitch',
                'flip',
                'shockwave',
                'prism',
                'kaleidoscope',
                'matrix',
                'ripple'
            ].includes(value)
        },
        config: {
            type: Object,
            default: () => ({})
        },
        shouldSync: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            timer: null,
            fadeClass: 'ss-slide-down',
            videoLoaded: false,
            showTransitions: false,
            currentSlideIndex: 0,
            slideshowTimer: null,
            lastSyncTime: 0
        };
    },
    computed: {
        showTransitionEffect() {
            return this.transitionEffect;
        },
        startFirstPart() {
            return this.startOrderText.split(' ')[0];
        },
        startSecondPart() {
            const words = this.startOrderText.split(' ');
            return words.slice(1).join(' ');
        },
        normalizedSlideshow() {
            return this.slideshowImages.map(item => {
                if (typeof item === 'string') {
                    return { url: item, duration: this.slideshowInterval / 1000 };
                }
                return item;
            });
        }
    },
    methods: {
        resetTimer() {
            if (this.previewMode) return; // Prevent timer reset in preview mode
            clearTimeout(this.timer);
            if (this.visible) {
                this.fadeClass = 'ss-fade-out';
                setTimeout(() => {
                    this.visible = false;
                    this.fadeClass = '';
                }, 1000); // Duration of fade-out animation
            }
            this.timer = setTimeout(() => {
                this.visible = true;
                this.$emit('isVisible', true);
                this.fadeClass = 'ss-slide-down';
            }, this.idleTimeSeconds * 1000);
        },
        handleClick() {
            if (this.previewMode) return; // Prevent visibility change in preview mode
            this.$emit('isVisible', false);
            this.resetTimer();
        },
        onVideoLoaded() {
            this.videoLoaded = true;
            this.fadeIn = true;
        },
        startSlideshow() {
            if (this.backgroundType === 'slideshow' && this.normalizedSlideshow.length > 0) {

                const syncSlides = () => {
                    const totalDuration = this.normalizedSlideshow.reduce((sum, slide) => sum + (slide.duration * 1000), 0);
                    
                    if (this.shouldSync) {
                        // Synchronized with start of day
                        const now = new Date();
                        const midnight = new Date(now).setHours(0,0,0,0);
                        const timeSinceStartOfDay = now.getTime() - midnight;
                        const timeSinceStart = timeSinceStartOfDay % totalDuration;
                        
                        // Calculate which slide should be showing based on elapsed time
                        let timeSum = 0;
                        for (let i = 0; i < this.normalizedSlideshow.length; i++) {
                            timeSum += this.normalizedSlideshow[i].duration * 1000;
                            if (timeSinceStart < timeSum) {
                                
                                this.currentSlideIndex = i;
                                break;
                            }
                        }

                        // Schedule next update at exact transition time
                        const timeUntilNext = timeSum - timeSinceStart;
                        this.slideshowTimer = setTimeout(syncSlides, timeUntilNext);
                    } else {
                        // Simple interval-based slideshow
                        this.currentSlideIndex = (this.currentSlideIndex + 1) % this.normalizedSlideshow.length;
                        const nextDuration = this.normalizedSlideshow[this.currentSlideIndex].duration * 1000;
                        this.slideshowTimer = setTimeout(syncSlides, nextDuration);
                    }
                };

                syncSlides();
            }
        },
        stopSlideshow() {
            if (this.slideshowTimer) {
                clearInterval(this.slideshowTimer);
                this.slideshowTimer = null;
            }
        }
    },
    watch: {
        enabled(newVal) {
            console.log('enabled prop changed:', newVal);
            if (newVal) {
                console.log('enabled is true');
                this.visible = true;
                this.fadeClass = this.previewMode ? '' : 'ss-fade-in';
                this.$emit('isVisible', true);
                if (!this.previewMode) {
                    this.resetTimer();
                }
            } else {
                console.log('enabled is false');
                this.visible = false;
                this.fadeClass = '';
                if (!this.previewMode) {
                    clearTimeout(this.timer);
                }
            }
        },
        visible(newVal) {
            if (newVal && !this.previewMode) {  // Only start slideshow if not in preview mode
                this.startSlideshow();
            } else if (!newVal && !this.previewMode) {  // Only stop slideshow if not in preview mode
                this.stopSlideshow();
            }
        }
    },
    mounted() {
        if (!this.previewMode) {
            this.resetTimer();
        } else {
            this.visible = this.enabled;
            if (this.enabled && this.backgroundType === 'slideshow') {
                this.startSlideshow();
            }
        }
        window.addEventListener('keydown', this.resetTimer);
        window.addEventListener('click', this.resetTimer);
    },
    unmounted() {
        clearTimeout(this.timer); // Clear timer on component destruction
        window.removeEventListener('keydown', this.resetTimer);
        window.removeEventListener('click', this.resetTimer);
        this.showTransitions = false;
        this.stopSlideshow();
    }
};
</script>

<style scoped>
.screensaver {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 4rem;
    z-index: 9999;
    cursor: pointer;
}

.screensaver-content {
    display: flex;
    flex-direction: column;
    /* Arrange children vertically */
    justify-content: center;
    /* Center children vertically */
    align-items: center;
    /* Center children horizontally */
    text-align: center;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.screensaver-content p {
    margin: 1rem 0;
    opacity: 0;
    transform: translateY(20px);
    animation: ssFadeInSlideUp 1s forwards;
}

.order-here {
    line-height: 12rem;
    font-size: 12rem;
    font-family: 'Roboto', sans-serif;
}


.order-here.here,
.order-here.order {
    font-size: 14.5rem;
    color: #fff;
    font-weight: 900;
}

.tap-screen {
    padding-top: 2rem;
    font-size: 2.9rem;
    font-weight: 100;
}

.background-video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fallback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ss-fade-in {
    animation: ssFadeIn 1s;
}

.ss-fade-out {
    animation: ssFadeOut 1s;
}


@keyframes ssFadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes ssFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes ssFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.background-slideshow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: black;
    overflow: hidden;
}

.slideshow-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    z-index: 0;
    background-color: black;
}

.slideshow-image.active {
    z-index: 1;
}

.transition-none {
    transition: none;
    opacity: 1;
}

/* Standard Fade - Enhanced with cross-fade */
.transition-fade {
    transition: opacity 2.5s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: opacity;
}

.transition-fade.active {
    opacity: 1;
}

/* Zoom Effect - Smoother with subtle rotation */
.transition-zoom {
    transition: opacity 2s cubic-bezier(0.4, 0, 0.2, 1),
                transform 8s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(1.25) rotate(0.5deg);
    will-change: transform, opacity;
}

.transition-zoom.active {
    opacity: 1;
    transform: scale(1) rotate(0deg);
}

/* Slide from Left - With depth effect */
.transition-slide-left {
    transition: opacity 1.5s cubic-bezier(0.4, 0, 0.2, 1),
                transform 1.5s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(-100%) scale(0.95);
    will-change: transform, opacity;
}

.transition-slide-left.active {
    opacity: 1;
    transform: translateX(0) scale(1);
}

/* Slide Up - With subtle zoom */
.transition-slide-up {
    transition: opacity 1.5s cubic-bezier(0.4, 0, 0.2, 1),
                transform 1.5s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(100%) scale(1.1);
    will-change: transform, opacity;
}

.transition-slide-up.active {
    opacity: 1;
    transform: translateY(0) scale(1);
}

/* Ken Burns Effect - More dynamic and smooth */
.transition-ken-burns {
    transition: opacity 3s ease-in-out,
                transform 30s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: scale(1.5) translate(-3%, -3%);
    transform-origin: center center;
    will-change: transform, opacity;
}

.transition-ken-burns.active {
    opacity: 1;
    transform: scale(1.1) translate(3%, 3%);
}

/* Alternate Ken Burns animation */
.transition-ken-burns:nth-child(2n) {
    transform: scale(1.1) translate(3%, -3%) rotate(0.5deg);
}

.transition-ken-burns:nth-child(2n).active {
    transform: scale(1.5) translate(-3%, 3%) rotate(-0.5deg);
}

.transition-ken-burns:nth-child(3n) {
    transform: scale(1.3) translate(-2%, 2%) rotate(-0.5deg);
}

.transition-ken-burns:nth-child(3n).active {
    transform: scale(1.1) translate(2%, -2%) rotate(0.5deg);
}

.screensaver-logo {
    width: 8.25rem;
    height: 8.25rem;
    margin-bottom: 2rem;
    animation: ssFadeInSlideUp 1s forwards;
    opacity: 0;
    transform: translateY(20px);
}

/* Spiral Zoom - Creates a dramatic spiral entrance */
.transition-spiral {
    transition: opacity 2s cubic-bezier(0.4, 0, 0.2, 1),
                transform 2s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(0) rotate(-540deg);
    transform-origin: center center;
    will-change: transform, opacity;
}

.transition-spiral.active {
    opacity: 1;
    transform: scale(1) rotate(0deg);
}

/* Blinds Effect - Reveals image through vertical strips */
.transition-blinds {
    clip-path: polygon(
        0 0, 10% 0, 10% 100%, 0 100%,
        20% 0, 30% 0, 30% 100%, 20% 100%,
        40% 0, 50% 0, 50% 100%, 40% 100%,
        60% 0, 70% 0, 70% 100%, 60% 100%,
        80% 0, 90% 0, 90% 100%, 80% 100%,
        100% 0, 100% 100%
    );
    transition: opacity 1.5s ease-in-out,
                clip-path 1.5s ease-in-out;
    will-change: clip-path, opacity;
}

.transition-blinds.active {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* Morph - Creates a liquid-like transition */
.transition-morph {
    transition: opacity 2s ease-in-out,
                clip-path 2s cubic-bezier(0.7, 0, 0.3, 1);
    clip-path: circle(0% at center);
    will-change: clip-path, opacity;
}

.transition-morph.active {
    opacity: 1;
    clip-path: circle(150% at center);
}

/* Glitch - Adds a trendy glitch effect */
@keyframes glitch {
    0% { transform: translate(0); filter: hue-rotate(0deg); }
    25% { transform: translate(-2px, 2px); filter: hue-rotate(90deg); }
    50% { transform: translate(2px, -2px); filter: hue-rotate(180deg); }
    75% { transform: translate(-2px, -2px); filter: hue-rotate(270deg); }
    100% { transform: translate(0); filter: hue-rotate(360deg); }
}

.transition-glitch {
    transition: opacity 0.5s ease;
    will-change: transform, filter, opacity;
}

.transition-glitch.active {
    opacity: 1;
    animation: glitch 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* 3D Flip - Creates a dramatic 3D rotation with random directions */
.transition-flip {
    transition: opacity 1s ease-in-out,
                transform 1s cubic-bezier(0.4, 0, 0.2, 1);
    transform: perspective(1000px);
    transform-style: preserve-3d;
    will-change: transform, opacity;
}

.transition-flip:nth-child(4n) {
    transform: perspective(1000px) rotateY(90deg);
    transform-origin: left center;
}

.transition-flip:nth-child(4n+1) {
    transform: perspective(1000px) rotateY(-90deg);
    transform-origin: right center;
}

.transition-flip:nth-child(4n+2) {
    transform: perspective(1000px) rotateX(90deg);
    transform-origin: top center;
}

.transition-flip:nth-child(4n+3) {
    transform: perspective(1000px) rotateX(-90deg);
    transform-origin: bottom center;
}

.transition-flip.active {
    opacity: 1;
    transform: perspective(1000px) rotate(0deg) !important;
}

/* Shockwave - Creates a ripple effect */
@keyframes shockwave {
    0% { transform: scale(1); filter: brightness(1) blur(0px); }
    50% { transform: scale(1.05); filter: brightness(1.2) blur(3px); }
    100% { transform: scale(1); filter: brightness(1) blur(0px); }
}

.transition-shockwave {
    transition: opacity 1s ease-in-out;
    will-change: transform, filter, opacity;
}

.transition-shockwave.active {
    opacity: 1;
    animation: shockwave 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Prism - Creates a prismatic color split effect */
.transition-prism {
    transition: opacity 2s ease-in-out,
                transform 2s cubic-bezier(0.4, 0, 0.2, 1);
    filter: opacity(0) hue-rotate(90deg) saturate(150%);
    transform: scale(1.1) skew(5deg);
    will-change: transform, filter, opacity;
}

.transition-prism.active {
    opacity: 1;
    filter: opacity(1) hue-rotate(0deg) saturate(100%);
    transform: scale(1) skew(0deg);
}

/* Origami - Unfolds like paper */
.transition-origami {
    transition: opacity 1.5s ease-in-out,
                clip-path 1.5s cubic-bezier(0.4, 0, 0.2, 1);
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
    will-change: clip-path, opacity;
}

.transition-origami.active {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* Kaleidoscope - Creates a mesmerizing kaleidoscope effect */
@keyframes kaleidoscope {
    0% {
        filter: hue-rotate(0deg) saturate(100%);
        transform: rotate(0deg) scale(1.5);
    }
    50% {
        filter: hue-rotate(180deg) saturate(200%);
        transform: rotate(180deg) scale(1.2);
    }
    100% {
        filter: hue-rotate(360deg) saturate(100%);
        transform: rotate(360deg) scale(1);
    }
}

.transition-kaleidoscope {
    transition: opacity 0.5s ease-in-out;
    will-change: transform, filter, opacity;
}

.transition-kaleidoscope.active {
    opacity: 1;
    animation: kaleidoscope 2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Matrix - Digital rain effect */
@keyframes matrix {
    0% {
        opacity: 0;
        filter: brightness(1.5) saturate(0) contrast(2);
        transform: scale(1.1) translateY(-10%);
    }
    50% {
        filter: brightness(1) saturate(0) contrast(1.5);
    }
    100% {
        opacity: 1;
        filter: brightness(1) saturate(1) contrast(1);
        transform: scale(1) translateY(0);
    }
}

.transition-matrix {
    transition: opacity 0.5s ease-in-out;
    will-change: transform, filter, opacity;
}

.transition-matrix.active {
    opacity: 1;
    animation: matrix 2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Ripple - Concentric circles reveal */
@keyframes ripple {
    0% {
        clip-path: circle(5% at center);
        filter: brightness(1.5) contrast(1.2);
    }
    100% {
        clip-path: circle(150% at center);
        filter: brightness(1) contrast(1);
    }
}

.transition-ripple {
    transition: opacity 0.5s ease-in-out;
    will-change: clip-path, filter, opacity;
}

.transition-ripple.active {
    opacity: 1;
    animation: ripple 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.ss-slide-down {
    animation: ssSlideDown 1s forwards;
}

@keyframes ssSlideDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.progress-indicator {
    position: absolute;
    top: 0.625rem; /* 10px / 16 */
    left: 0.625rem; /* 10px / 16 */
    display: flex;
    z-index: 2;
}

.progress-dot {
    width: 0.625rem; /* 10px / 16 */
    height: 0.625rem; /* 10px / 16 */
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    margin-right: 0.3125rem; /* 5px / 16 */
    transition: background-color 0.3s;
}

.progress-dot.active {
    background-color: rgba(255, 255, 255, 1);
}
</style>