import {
  subtotalItem,
  findOrCreateModifierSet,
  toggleModifierInSet,
} from "@/utils/helpers";

export default {
  namespaced: true,
  state: {
    item: null,
    itemTotal: 0,
    instructions: "",
    lineModifierSets: [],
    quantity: 1,
    editIndex: null,
    editMode: false,
  },
  getters: {
    item: (state) => state.item,
    instructions: (state) => state.instructions,
    quantity: (state) => state.quantity,
    itemTotal: (state) => state.itemTotal,
    lineModifierSets: (state) => state.lineModifierSets,
    editIndex: (state) => state.editIndex,
    subtotal: (state) => {
      if (!state.item) {
        return 0;
      }
      const itemCopy = {
        ...state.item,
        quantity: state.quantity,
        lineModifierSets: state.lineModifierSets,
      };

      return subtotalItem(itemCopy);
    },
    valid(state) {
      if (!state.item) {
        return false;
      }
  
      for (const modSet of state.item.modifierSets) {
        const selectedModifiers = state.lineModifierSets.find(
          (lms) => lms.modifierSetRef === modSet.ref
        )?.lineModifiers || [];
  
        const min = modSet.min || 0;
        const max = modSet.max === undefined ? selectedModifiers.length : modSet.max;
  
        if (selectedModifiers.length < min || selectedModifiers.length > max) {
          return false;
        }
      }
  
      return state.quantity > 0;
    },
    requiredModSets: (state) => {
      if (!state.item) return [];
      return state.item.modifierSets.filter(modSet => modSet.min > 0);
    },
    optionalModSets: (state) => {
      if (!state.item) return [];
      return state.item.modifierSets.filter(modSet => modSet.min === 0);
    },
    isModifierSelected: (state) => ({ modifierSetRef, modifierRef }) => {
      const modSet = state.lineModifierSets.find(
        (ms) => ms.modifierSetRef === modifierSetRef
      );
      if (!modSet) return false;
      
      return modSet.lineModifiers.some(
        (mod) => mod.modifierRef === modifierRef
      );
    },
    getModifiersForSet: (state) => (modifierSetRef) => {
      const modSet = state.lineModifierSets.find(
        (ms) => ms.modifierSetRef === modifierSetRef
      );
      return modSet ? modSet.lineModifiers : [];
    }
  },
  mutations: {
    RESET_ITEM(state) {
      state.quantity = 1;
      state.item = null;
      state.lineModifierSets = [];
      state.itemTotal = 0;
      state.instructions = "";
    },
    SET_EDIT_INDEX(state, editIndex) {
      state.editIndex = editIndex;
    },
    SET_EDIT_MODE(state, mode) {
      state.editMode = mode;
    },
    SET_LINE_MODIFIER_SETS(state, sets) {
      state.lineModifierSets = sets;
    },
    SET_INSTRUCTIONS(state, instructions) {
      state.instructions = instructions;
    },
    SET_ITEM(state, item) {
      state.item = item;
    },
    SET_ITEM_TOTAL(state, total) {
      state.itemTotal = total;
    },
    SET_QUANTITY(state, quantity) {
      state.quantity = quantity;
    },
    TOGGLE_MODIFIER(state, { modifierSetRef, modifierSetName, modifier }) {
      if (!modifier || !modifierSetRef) return;
      const modSet = findOrCreateModifierSet(
        state,
        modifierSetRef,
        modifierSetName
      );
      toggleModifierInSet(modSet, modifier);
    },
    UNSET_NESTED_MODIFIERS_FOR_SET(
      state,
      { parentModifierSetRef, parentModifierRef, modifierSetRef }
    ) {
      if (!parentModifierSetRef || !parentModifierRef || !modifierSetRef)
        return;
      const parentModSet = findOrCreateModifierSet(state, parentModifierSetRef);
      const parentMod = parentModSet.lineModifiers.find(
        (m) => m.modifierRef === parentModifierRef
      );
      if (!parentMod) return;
      const nestedModSet = parentMod.lineModifierSets.find(
        (ms) => ms.modifierSetRef === modifierSetRef
      );
      if (nestedModSet) {
        nestedModSet.lineModifiers = [];
      }
    },
    UNSET_MODIFIERS_FOR_SET(state, { modifierSetRef }) {
      if (!modifierSetRef) return;
      const modSet = findOrCreateModifierSet(state, modifierSetRef);
      modSet.lineModifiers = [];
    },
    TOGGLE_NESTED_MODIFIER(
      state,
      {
        parentModifierSetRef,
        parentModifierRef,
        nestedModifierSetRef,
        modifier,
      }
    ) {
      if (
        !parentModifierSetRef ||
        !parentModifierRef ||
        !nestedModifierSetRef ||
        !modifier
      ) {
        return;
      }

      const parentModSet = state.lineModifierSets.find(
        (ms) => ms.modifierSetRef === parentModifierSetRef
      );
      if (!parentModSet) return;

      const parentMod = parentModSet.lineModifiers.find(
        (m) => m.modifierRef === parentModifierRef
      );
      if (!parentMod) return;

      let nestedModSet = parentMod.lineModifierSets.find(
        (ms) => ms.modifierSetRef === nestedModifierSetRef
      );
      if (!nestedModSet) {
        nestedModSet = {
          name: '',
          lineModifiers: [],
          modifierSetRef: nestedModifierSetRef,
        };
        parentMod.lineModifierSets.push(nestedModSet);
      }

      toggleModifierInSet(nestedModSet, modifier);
    },
    RESTORE_NESTED_MODIFIERS(state, { parentModifierSetRef, parentModifierRef, modifiers }) {
      const parentModSet = state.lineModifierSets.find(
        (ms) => ms.modifierSetRef === parentModifierSetRef
      );
      if (!parentModSet) return;

      const parentMod = parentModSet.lineModifiers.find(
        (m) => m.modifierRef === parentModifierRef
      );
      if (!parentMod) return;

      if (!parentMod.lineModifierSets) {
        parentMod.lineModifierSets = [];
      }

      parentMod.lineModifierSets = modifiers;
    },
  },
  actions: {
    start({ commit }, { item, quantity = 1, lineModifierSets = [], instructions = "", editIndex }) {
      commit("SET_ITEM", item);
      commit("SET_QUANTITY", quantity);
      commit("SET_LINE_MODIFIER_SETS", lineModifierSets);
      commit("SET_INSTRUCTIONS", instructions);
      
      if (editIndex !== undefined) {
        commit("SET_EDIT_INDEX", editIndex);
        commit("SET_EDIT_MODE", true);
      } else {
        commit("SET_EDIT_INDEX", null);
        commit("SET_EDIT_MODE", false);
      }
    },
    updateItem({ state, commit, dispatch }) {
      if (state.editMode) {
        dispatch(
          "order/updateItem",
          {
            index: state.editIndex,
            itemRef: state.item.ref,
            price: state.item.price,
            name: state.item.name,
            instructions: state.item.instructions,
            quantity: state.quantity,
            lineModifierSets: state.lineModifierSets,
          },
          { root: true }
        );
        state.item.instructions = '';
      } else {
        commit(
          "order/UPDATE_ITEM",
          {
            index: state.editIndex,
            itemRef: state.item.ref,
            instructions: state.item.instructions,
            price: state.item.price,
            name: state.item.name,
            quantity: state.quantity,
            lineModifierSets: state.lineModifierSets,
          },
          { root: true }
        );
        state.item.instructions = '';
      }
      console.log('orderItem.updateItem', state.item);
      commit("RESET_ITEM");
    },
    reset({ commit }) {
      console.log('orderItem.reset');
      commit("RESET_ITEM");
    },
    close({ commit }) {
      console.log('orderItem.close');
      commit("RESET_ITEM");
    },
    order({ commit, state, dispatch }, item) {
      const itemImage = item.images?.[0] || item.image || null;
      const orderData = {
        itemRef: state.item.ref,
        price: state.item.price,
        name: state.item.name,
        image: itemImage,
        instructions: state.item.instructions,
        quantity: state.quantity,
        lineModifierSets: state.lineModifierSets,
      };
      
      // Reset state before dispatching to prevent unmounting issues
      commit("RESET_ITEM");
      
      // Dispatch after state is cleaned up
      dispatch(
        "order/addItem",
        orderData,
        { root: true }
      );
    },
    updateQuantity({ commit }, quantity) {
      commit("SET_QUANTITY", quantity);
    },
  },
};
