<template>
    <div class="mod-set">
        <audio ref="clickSound" src="/sounds/click.mp3" preload="auto"></audio>
        <div class="mod-set-header">
            <div class="mod-set-title">{{ modSet.name }}</div>
            <div v-if="showInstructions" class="mod-set-instructions" v-html="instructions"></div>
        </div>
        <div :class="['mod-set-options', { 'two-cols': isVertical }]">
            <div class="mod-option" v-for="mod in modSet.modifiers" :key="mod.id" :class="{
                'highlight': isSelected(mod),
                'disabled': !canSelect(mod)
            }" @click="handleModifierClick(mod)">
                <div class="mod-option-content">
                    <div class="icon">
                        <SvgIcon :name="getControlIcon(mod)" />
                    </div>
                    <span style="margin-top: 0.25rem;">{{ mod.name }} {{ formatPrice(mod.price) }}</span>
                </div>
                <div class="chevron-container">
                    <SvgIcon v-if="mod.modifierSets?.length" width="2rem" height="2rem" name="nestedModSet"
                        class="chevron-icon" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        modSet: {
            type: Object,
            required: true
        },
        showInstructions: {
            type: Boolean,
            default: false
        },
        selectedGraph: {
            type: Array,
            required: true
        },
        parentModSetRef: {
            type: String,
            required: false,
            default: null
        },
        parentModRef: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        ...mapGetters(['screenOrientation']),
        isVertical() {
            return this.screenOrientation !== 'horizontal';
        },
        modType() {
            const { max } = this.modSet;
            if (max === 1) {
                return "radio";
            } else {
                return "checkbox";
            }
        },
        required() {
            return this.modSet.min > 0;
        },
        isMaxSelected() {
            if (this.modType === "radio") return false;
            const currentSelections = this.$store.getters['orderItem/getModifiersForSet'](this.modSet.ref);
            return currentSelections.length >= this.modSet.max;
        },
        instructions() {
            const { min, max } = this.modSet;
            const totalModifiers = this.modSet?.modifiers?.length || 0;
            let instruction = '';

            if (min === 1 && max === 1) {
                instruction = this.$t("chooseOne");
            } else if (min === 0 && max === 1) {
                instruction = this.$t("optional");
            } else if (min > 0 && max === undefined) {
                instruction = `${this.$t('chooseAtLeast')} <strong>${min}</strong>`;
            } else if (min > 0 && max > min) {
                instruction = `${this.$t('chooseBetween')} <strong>${min}</strong> - <strong>${totalModifiers}</strong>`;
            } else if (!min && max > 0 && max !== this.modSet.modifiers.length) {
                const maxString = max > this.modSet.modifiers.length ? this.modSet.modifiers.length : max;
                instruction = `${this.$t('chooseUpTo')} <strong>${maxString}</strong>`;
            } else if (min > 0 && max === min) {
                instruction = `${this.$t('choose')} <strong>${min}</strong>`;
            }

            return instruction ? `- ${instruction}` : '';
        },
        isRequirementMet() {
            const currentSelections = this.$store.getters['orderItem/getModifiersForSet'](this.modSet.ref);
            const min = this.modSet.min || 0;
            const max = this.modSet.max === undefined ? currentSelections.length : this.modSet.max;
            return currentSelections.length >= min && currentSelections.length <= max;
        }
    },
    methods: {
        ...mapGetters(['screenOrientation']),
        isSelected(mod) {
            return this.selectedGraph.some(selected => selected.modifierRef === mod.ref);
        },

        canSelect(mod) {
            if (this.isSelected(mod)) return true;
            if (this.modType === 'radio') return true;

            const currentSelections = this.$store.getters['orderItem/getModifiersForSet'](this.modSet.ref);
            return currentSelections.length < this.modSet.max;
        },

        toggleModifier(mod) {
            if (this.disabled) return;

            if (this.parentModSetRef && this.parentModRef) {

                if (this.modType === "radio") {
                    // First unset all modifiers for this set
                    this.$store.commit("orderItem/UNSET_NESTED_MODIFIERS_FOR_SET", {
                        parentModifierSetRef: this.parentModSetRef,
                        parentModifierRef: this.parentModRef,
                        nestedModifierSetRef: this.modSet.ref,
                    });

                    // Then set the new selection if it wasn't already selected
                    if (!this.isSelected(mod)) {
                        this.$store.commit("orderItem/TOGGLE_NESTED_MODIFIER", {
                            parentModifierSetRef: this.parentModSetRef,
                            parentModifierRef: this.parentModRef,
                            nestedModifierSetRef: this.modSet.ref,
                            modifier: mod,
                        });
                    }
                } else {
                    // For checkboxes, just toggle the selection
                    this.$store.commit("orderItem/TOGGLE_NESTED_MODIFIER", {
                        parentModifierSetRef: this.parentModSetRef,
                        parentModifierRef: this.parentModRef,
                        nestedModifierSetRef: this.modSet.ref,
                        modifier: mod,
                    });
                }

                return;
            }


            if (this.modType === "radio") {
                // First unset all modifiers for this set
                this.$store.commit("orderItem/UNSET_MODIFIERS_FOR_SET", {
                    modifierSetRef: this.modSet.ref,
                });

                // Then set the new selection if it wasn't already selected
                if (!this.isSelected(mod)) {
                    this.$store.commit("orderItem/TOGGLE_MODIFIER", {
                        modifierSetRef: this.modSet.ref,
                        modifierSetName: this.modSet.name,
                        modifier: mod,
                    });
                }
            } else {
                // For checkboxes, just toggle the selection
                this.$store.commit("orderItem/TOGGLE_MODIFIER", {
                    modifierSetRef: this.modSet.ref,
                    modifierSetName: this.modSet.name,
                    modifier: mod,
                });
            }
        },

        formatPrice(price) {
            return price > 0 ? `+$${(price / 100).toFixed(2)}` : '';
        },

        getControlIcon(mod) {
            const type = this.modType;
            const state = this.isSelected(mod) ? "on" : "off";
            return `${type}-${state}`;
        },

        playClickSound() {
            const audio = this.$refs.clickSound;
            if (audio) {
                audio.currentTime = 0;
                audio.play().catch(e => console.log('Audio play failed:', e));
            }
        },

        handleModifierClick(mod) {
            this.playClickSound();
            
            if (mod.modifierSets?.length && !this.isSelected(mod)) {
                this.$emit('select-nested', {
                    parentModSetRef: this.modSet.ref,
                    parentModRef: mod.ref,
                    modSet: mod.modifierSets[0]
                });
            }
            this.toggleModifier(mod);
        }
    },
    emits: ['select-nested']
}
</script>

<style scoped>
.mod-set {
    margin-bottom: 1.5rem;
}

.mod-set-header {
    display: flex;
    align-items: baseline;
    gap: 0.75rem;
    margin-bottom: 1.25rem;
}

.mod-set-title {
    font-size: 1.625rem;
    font-weight: 700;
    color: var(--primary-black);
}

.mod-set-instructions {
    font-size: 1.625rem;
    font-weight: 400;
    color: var(--grey-medium-emphasis);
}

.mod-set-options {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;
    grid-auto-rows: minmax(7.5rem, auto);
}

.two-cols {
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 768px) {

    /* Adjust the max-width value as needed */
    .mod-set-options {
        grid-template-columns: repeat(2, 1fr);
    }
}

.mod-option {
    position: relative;
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--grey-high-emphasis);
    width: auto;
    min-height: 7.5rem;
    height: 100%;
    border: 0.125rem solid var(--grey-outline);
    background-color: var(--primary-white);
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    cursor: pointer;
}

.mod-option-content {
    display: flex;
    align-items: flex-start;
}

.mod-option:last-child {
    margin-right: 0;
}

.mod-hold {
    display: flex;
    align-items: flex-start;
    padding: 1rem 1.5rem;
}

.icon {
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem !important;
    margin-right: 0.5625rem;
    margin-top: 0.125rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.mod-price {
    margin-left: 0.25rem;
}

.chevron-container {
    position: absolute;
    bottom: 1rem;
    right: 1.5rem;
}

.chevron-icon {
    color: var(--grey-medium-emphasis);
}

.mod-option.highlight {
    color: var(--brand-color);
}

.mod-option.highlight .icon {
    color: var(--brand-color);
}
</style>