<template>
    <transition name="fade" key="1">
        <div class="modal-overlay" @click="close" v-if="item" role="dialog" aria-labelledby="modal-header"
            aria-modal="true">
            <div class="modal-window" @click.stop>
                <div class="item-component">
                    <div class="header">
                        <div class="name">{{ item.name }}</div>
                        <button class="close-button" @click="close" aria-label="Close modal">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_5141_5915)">
                                    <rect width="56" height="56" rx="28" fill="#F5F5F5" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M35 22.41L33.59 21L28 26.59L22.41 21L21 22.41L26.59 28L21 33.59L22.41 35L28 29.41L33.59 35L35 33.59L29.41 28L35 22.41Z"
                                        fill="#666666" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_5141_5915">
                                        <rect width="56" height="56" rx="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div class="body">
                        <div class="information">
                            <div class="description">{{ item.description }}</div>
                            <quantity-selector v-if="showQuantity" @quantity="updateQuantity" />
                            <div class="image" v-if="item.images.length > 0">
                                <img :src="imageUrl(item.images[0])" :alt="item.name" @click="toggleFullscreen" />
                                <button class="zoom-icon" @click="toggleFullscreen" v-html="svgLib.zoom"></button>
                            </div>
                        </div>
                        <div class="choices">
                            <div v-if="currentNestedModSet">
                                <div class="title">{{ currentNestedModSet.parentMod.name }}</div>
                                <mod-set v-for="modSet in currentNestedModSet.parentMod.modifierSets" :key="modSet.id"
                                    :mod-set="modSet" :parent-mod-set-ref="currentNestedModSet.parentModSetRef"
                                    :parent-mod-ref="currentNestedModSet.parentModRef" :selected-graph="selectedNestedModifiers(
                                        currentNestedModSet.parentModSetRef,
                                        currentNestedModSet.parentModRef,
                                        modSet.ref
                                    )" :show-instructions="true" />
                            </div>
                            <div v-else-if="isShowingRequiredMods && showRequiredMods">
                                <div class="title">{{ $t('requiredSelections') || 'Required Selections' }}</div>
                                <mod-set v-for="modSet in requiredModSets" :key="modSet.id" :mod-set="modSet"
                                    :selected-graph="selectedModifiers(modSet.ref)" :show-instructions="true"
                                    @select-nested="showNestedModSet" />
                            </div>
                            <div v-else>
                                <div v-if="optionalModSets.length > 0">
                                    <div class="title">{{ $t('optionalSelections') || 'Optional Selections' }}</div>
                                    <mod-set v-for="modSet in optionalModSets" :key="modSet.id" :mod-set="modSet"
                                        :selected-graph="selectedModifiers(modSet.ref)"
                                        @select-nested="showNestedModSet" />
                                </div>
                                <special-request :visible="showSpecialRequest" :char-limit="32"
                                    :initial-special-request="specialRequest"
                                    @update:specialRequest="updateSpecialRequest" />
                            </div>
                        </div>
                    </div>
                    <div class="footer d-flex justify-content-between">
                        <div class="footer-column left">
                            <button v-if="currentNestedModSet" class="btn btn-lg btn-outline-primary d-block"
                                @click="cancelNestedModifications">
                                {{ $t('cancelButtonText') || 'Cancel' }}
                            </button>
                            <button v-else-if="!isShowingRequiredMods" class="btn btn-lg btn-outline-primary d-block"
                                @click="toggleModView">
                                {{ $t('backButtonText') || 'Back' }}
                            </button>
                        </div>
                        <div class="footer-column right">
                            <button v-if="currentNestedModSet" class="btn btn-lg btn-primary d-block"
                                @click="saveNestedModifications" :disabled="!nestedSelectionsValid">
                                {{ $t('submitButtonText') || 'Submit' }}
                            </button>
                            <button v-else-if="isShowingRequiredMods && showRequiredMods"
                                class="btn btn-lg btn-primary d-block" @click="toggleModView"
                                :disabled="!requiredSelectionsValid">
                                {{ $t('continueButtonText') || 'Continue' }}
                            </button>
                            <button v-else class="btn btn-lg btn-primary d-block" @click="order">
                                {{ isEdit ? $t('updateItem') || 'Update Item' : $t('addToCart') || 'Add to Cart' }} {{ formatCurrency(subtotal) }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <div v-if="isFullscreen" class="fullscreen-overlay" @click="toggleFullscreen">
        <img :src="imageUrl(item.images[0])" :alt="item.name" class="fullscreen-image" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { imageUrl, formatCurrency } from "@/utils/helpers";
import QuantitySelector from "@/components/features/itemOrderV2/QuantitySelector.vue";
import SpecialRequest from "@/components/features/itemOrderV2/SpecialRequest.vue";
import svgLib from "@/utils/svgLib";
import ModSet from './ModSet.vue';

export default {
    components: {
        QuantitySelector,
        ModSet,
        SpecialRequest
    },
    data() {
        return {
            svgLib,
            isFullscreen: false,
            isShowingRequiredMods: true,
            currentNestedModSet: null
        };
    },
    mounted() {
        this.isShowingRequiredMods = this.requiredModSets.length > 0;
    },
    props: {
        showQuantity: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters("orderItem", [
            "item",
            "subtotal",
            "quantity",
            "specialRequest",
            "lineModifierSets",
            "requiredModSets",
            "optionalModSets",
            "valid",
            "editIndex"
        ]),
        ...mapGetters("location", ['config']),
        showRequiredMods() {
            return this.requiredModSets.length > 0;
        },
        requiredSelectionsValid() {
            return this.valid;
        },
        isEdit() {
            return this.editIndex !== null;
        },
        nestedSelectionsValid() {
            if (!this.currentNestedModSet) return false;

            return this.currentNestedModSet.parentMod.modifierSets.every(modSet => {
                const selections = this.selectedNestedModifiers(
                    this.currentNestedModSet.parentModSetRef,
                    this.currentNestedModSet.parentModRef,
                    modSet.ref
                );

                const min = modSet.min || 0;
                const max = modSet.max === undefined ? selections.length : modSet.max;

                return selections.length >= min && selections.length <= max;
            });
        },
        showSpecialRequest() {
            if (this.item && Array.isArray(this.item.customLabels) && this.item.customLabels.includes('SPECIAL_REQUEST')) {
                return true;
            }
            return this.config.allowSpecialRequests;
        }
    },
    watch: {
        item(newItem) {
            if (newItem) {
                this.isShowingRequiredMods = this.requiredModSets.length > 0;
            }
        }
    },
    methods: {
        imageUrl,
        formatCurrency,
        selectedModifiers(modifierSetRef) {
            const ms = this.lineModifierSets.find(
                (lms) => lms.modifierSetRef === modifierSetRef
            );
            return ms ? ms.lineModifiers : [];
        },
        close() {
            const hasModifications = this.lineModifierSets.some(set => set.lineModifiers.length > 0) ||
                this.quantity > 1 ||
                this.item.instructions;

            if (hasModifications) {
                this.$store.dispatch("dialog/openDialog", {
                    title: "Confirm Close",
                    message: "Are you sure you want to close? Your changes will not be saved.",
                    showCancel: true,
                    onConfirm: () => {
                        this.isShowingRequiredMods = true;
                        this.$store.dispatch("orderItem/close");
                        this.$emit("close");
                    },
                });
            } else {
                this.isShowingRequiredMods = true;
                this.$store.dispatch("orderItem/close");
                this.$emit("close");
            }
        },
        updateQuantity(newQuantity) {
            this.$store.dispatch("orderItem/updateQuantity", newQuantity);
        },
        order() {
            if (this.isEdit) {
                this.$store.dispatch("orderItem/updateItem");
            } else {
                this.$store.dispatch("orderItem/order", {
                    ...this.item,
                });
            }
        },
        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
        },
        addSpecialRequest() {
            // Logic to add a special request
        },
        toggleModView() {
            this.isShowingRequiredMods = !this.isShowingRequiredMods;
            this.$nextTick(() => {
                const choicesDiv = document.querySelector('.choices');
                if (choicesDiv) {
                    choicesDiv.scrollTop = 0;
                }
            });
        },
        updateSpecialRequest(newRequest) {
            this.$store.dispatch("orderItem/updateSpecialRequest", newRequest);
        },
        showNestedModSet({ parentModSetRef, parentModRef, modSet }) {
            const parentMod = this.findParentMod(parentModSetRef, parentModRef);
            this.currentNestedModSet = {
                parentModSetRef,
                parentModRef,
                parentMod,
                originalState: JSON.parse(JSON.stringify(this.selectedNestedModifiers(
                    parentModSetRef,
                    parentModRef,
                    modSet.ref
                )))
            };

            // Reset the nested state for this specific parent modifier
            this.$store.commit('orderItem/UNSET_NESTED_MODIFIERS_FOR_SET', {
                parentModifierSetRef: parentModSetRef,
                parentModifierRef: parentModRef
            });
        },
        findParentMod(parentModSetRef, parentModRef) {
            const modSet = this.item.modifierSets.find(ms => ms.ref === parentModSetRef);
            return modSet?.modifiers.find(mod => mod.ref === parentModRef);
        },
        selectedNestedModifiers(parentModSetRef, parentModRef, nestedModSetRef) {
            //console.log('selectedNestedModifiers called with:', { parentModSetRef, parentModRef, nestedModSetRef });

            const parentSet = this.lineModifierSets.find(
                lms => lms.modifierSetRef === parentModSetRef
            );
            //console.log('Found parentSet:', parentSet);

            if (!parentSet) {
                //console.log('No parentSet found, returning empty array.');
                return [];
            }

            const parentMod = parentSet.lineModifiers.find(
                mod => mod.modifierRef === parentModRef
            );
            //console.log('Found parentMod:', parentMod);

            if (!parentMod) {
                //console.log('No parentMod found, returning empty array.');
                return [];
            }

            //console.log('parentMod.lineModifierSets:', parentMod.lineModifierSets);

            // Ensure lineModifierSets is an array and not empty
            if (!Array.isArray(parentMod.lineModifierSets) || parentMod.lineModifierSets.length === 0) {
                console.log('parentMod.lineModifierSets is not an array or is empty, returning empty array.');
                return [];
            }

            const nestedSet = parentMod.lineModifierSets.find(
                ms => ms.modifierSetRef === nestedModSetRef
            );
            //console.log('Found nestedSet:', nestedSet);

            if (!nestedSet) {
                //console.log('No nestedSet found, returning empty array.');
                return [];
            }

            console.log('nestedSet.lineModifiers:', nestedSet.lineModifiers);

            return nestedSet.lineModifiers || [];
        },
        handleBack() {
            if (this.currentNestedModSet) {
                this.currentNestedModSet = null;
            } else {
                this.toggleModView();
            }
        },
        cancelNestedModifications() {
            if (this.currentNestedModSet) {
                console.log('cancelNestedModifications called');
                const { parentModSetRef, parentModRef } = this.currentNestedModSet;
                this.$store.commit("orderItem/TOGGLE_MODIFIER", {
                    modifierSetRef: parentModSetRef,
                    modifierSetName: this.currentNestedModSet.parentMod.name,
                    modifier: {
                        ref: parentModRef,
                    }
                });

                if (this.currentNestedModSet.originalState) {
                    this.$store.commit('orderItem/RESTORE_NESTED_MODIFIERS', {
                        parentModifierSetRef: parentModSetRef,
                        parentModifierRef: parentModRef,
                        modifiers: this.currentNestedModSet.originalState
                    });
                }
            }
            this.currentNestedModSet = null;
        },
        saveNestedModifications() {
            this.currentNestedModSet = null;
        }
    },
    emits: ['close', 'back', 'startOver']
};
</script>

<style scoped>
.modal-overlay {
    font-size: 1rem;
    z-index: 5001;
    backdrop-filter: blur(0.625rem);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-window {
    background-color: white;
    border-radius: 1.5rem;
    overflow: hidden;
    width: calc(100vw - 10rem);
    height: calc(100vh - 2.5rem);
    display: flex;
    flex-direction: column;
}

.item-component {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary-black);
    border-bottom: 0.0625rem solid var(--grey-focus);
    flex-shrink: 0;
}

.close-button {
    margin: 0.6rem;
}

.body {
    display: flex;
    flex: 1;
    overflow-y: auto;
    background: var(--grey-surface-overlay-hover);
    padding: 2.125rem 0px;
    gap: 0;
}

.information,
.choices {
    flex: 1;
    overflow-y: auto;
    padding: 0px 2.375rem;
}

.information {
    flex: 0 0 36rem;
    min-width: 36rem;
    max-width: 36rem;
    border-right: 0.0625rem solid var(--grey-focus);
    font-size: 1.733rem;
    display: flex;
    flex-direction: column;
}

.description {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.625rem;
    line-height: 2.1875rem;
    color: var(--grey-medium);
    margin-bottom: 1.5rem;
    flex-shrink: 0;
}

.quantity-selector {
    flex-shrink: 0;
    margin-bottom: 1.5rem;
}

.image {
    flex: 1;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: 100%;
}

.image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
}

.choices {}

.choices .title {
    color: var(--grey-high-emphasis);
    font-family: Nunito Sans;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 2.89875rem;
    margin-bottom: 2.2rem;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.mod-set {
    margin-bottom: 1.5rem;
}

.mod-set-title {
    font-size: 1.625rem;
    font-weight: 700;
    color: var(--primary-black);
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.375rem 2.5rem;
    height: 9rem;
    flex-shrink: 0;
    border-top: 0.0625rem solid var(--grey-focus);
}

.footer-column {
    flex: 1;
    display: flex;
    justify-content: center;
}

.left {
    margin-right: 1.25rem;
}

.right {
    margin-left: 1.25rem;
}

.app-container .item-component .btn-lg {
    font-size: 2.125rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.3rem 2rem;

}

.app-container .item-component .btn-outline-primary {
    border-color: var(--brand-color) !important;
}

.zoom-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6000;
}

.fullscreen-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
</style>