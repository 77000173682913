<template>
    <div class="quantity-selector">
      <button 
        class="quantity-btn" 
        @click="updateQuantity(-1)"
        :disabled="quantity <= min"
      >
        −
      </button>
      
      <div class="quantity-display" @click="enableInput">
        <input
          v-if="isEditing"
          ref="inputRef"
          v-model="inputValue"
          type="number"
          :min="min"
          :max="max"
          @blur="handleBlur"
          @keyup.enter="handleBlur"
          @keydown="preventInvalidInput"
        />
        <span v-else>{{ quantity }}</span>
      </div>
  
      <button 
        class="quantity-btn"
        @click="updateQuantity(1)"
        :disabled="quantity >= max"
      >
        +
      </button>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  
  export default {
    props: {
      min: {
        type: Number,
        default: 1
      },
      max: {
        type: Number,
        default: 99
      },
      showQuantity: {
        type: Boolean,
        default: true
      }
    },
  
    data() {
      return {
        isEditing: false,
        inputValue: ''
      }
    },
  
    computed: {
      ...mapGetters('orderItem', ['quantity'])
    },
  
    methods: {
      updateQuantity(change) {
        const newValue = this.quantity + change
        if (newValue >= this.min && newValue <= this.max) {
          this.$store.dispatch('orderItem/updateQuantity', newValue)
        }
      },
  
      enableInput() {
        this.isEditing = true
        this.inputValue = this.quantity.toString()
        setTimeout(() => {
          this.$refs.inputRef?.focus()
        })
      },
  
      handleBlur() {
        const value = parseInt(this.inputValue)
        if (!isNaN(value) && value >= this.min && value <= this.max) {
          this.$store.dispatch('orderItem/updateQuantity', value)
        } else {
          // Reset to last valid value if input is invalid
          this.inputValue = this.quantity.toString()
        }
        this.isEditing = false
      },
  
      preventInvalidInput(event) {
        const allowedKeys = ['Backspace', 'Delete', 'Tab', 'Enter', 'Escape', '.', 'ArrowLeft', 'ArrowRight']
        if (allowedKeys.includes(event.key)) {
          return
        }
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault()
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .quantity-selector {
    display: flex;
    align-items: center;
    width: 11rem;
    /* pill */
    border-radius: 2rem;
    background: var(--primary-white);
    overflow: hidden;
    padding: 0.5rem 1rem;
  }
  
  .quantity-btn {
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quantity-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .quantity-display {
    flex: 1;
    text-align: center;
    font-size: 1.625rem;
    font-weight: 700;
    color: var(--primary-black);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .quantity-display input {
    width: 3rem;
    text-align: center;
    border: none;
    background: transparent;
    font-size: 1rem;
    padding: 0;
  }
  
  .quantity-display input::-webkit-inner-spin-button,
  .quantity-display input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  </style>
  