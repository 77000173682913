<template>

    <transition name="fade">
        <div v-if="showDialog" class="overlay"></div>
    </transition>
    <transition name="fade">
        <div v-if="showDialog" class="idle-dialog" role="alertdialog" aria-live="assertive"
            aria-labelledby="idleDialogTitle" aria-describedby="idleDialogDescription">
            <div>
                {{ $t('idleTriggeredMessage') }} <!--<strong class="seconds">{{ countdown }} seconds</strong>.-->
            </div>
            <progress max="100" :value="progressBarValue" class="countdown-progress"></progress>
            <div class="idle-dialog-buttons">
                <button class="cancel" @click="reset">{{ $t('startOverButtonText') }}</button>
                <button @click="continueOrdering">{{ $t('continueButtonText') }}</button>
            </div>

        </div>
    </transition>
    <div v-if="timeoutActive" class="idle-countdown">
        <svg class="countdown-ring" width="24" height="24" viewBox="0 0 24 24">
            <circle class="countdown-ring-bg" r="10" cx="12" cy="12"></circle>
            <circle 
                class="countdown-ring-progress" 
                r="10" 
                cx="12" 
                cy="12" 
                :style="{ strokeDashoffset: progressOffset }"
            ></circle>
        </svg>
    </div>
</template>

<script>
import eventBus from '@/eventBus';
export default {
    data() {
        return {
            idleTimeout: null,
            countdownTimeout: null,
            showDialog: false,
            initialCountdown: 10,
            countdown: 10,
            startTime: null, // Track the start time of the idle timer
            remainingTime: 0,
            updateInterval: null,
        };
    },
    props: {
        timeoutActive: {
            type: Boolean,
            default: true,
        },
        idleTimeoutSeconds: {
            type: Number,
            default: 30,
        },
    },
    emits: ['restart'],
    methods: {
        resetIdleTimer() {
            if (this.idleTimeoutSeconds <= 0) return; // Don't start timer if timeout is 0 or less
            clearTimeout(this.idleTimeout);
            clearInterval(this.updateInterval);
            this.startTime = Date.now(); // Set the start time
            this.remainingTime = this.idleTimeoutSeconds;
            this.idleTimeout = setTimeout(this.showIdleDialog, this.adjustedTimeout);
            this.updateInterval = setInterval(this.updateRemainingTime, 1000);
        },
        showIdleDialog() {
            if (this.idleTimeoutSeconds <= 0) return; // Don't show dialog if timeout is 0 or less
            this.defocusInput();
            this.showDialog = true;
            this.startCountdown();
        },
        defocusInput() {
            const focusedElement = document.activeElement;
            if (focusedElement && focusedElement !== document.body) {
                focusedElement.blur();
            }
        },
        startCountdown() {
            clearTimeout(this.countdownTimeout);  // Clear previous countdowns
            if (this.countdown > 0) {
                this.countdownTimeout = setTimeout(() => {
                    this.countdown -= 1;
                    this.startCountdown();
                }, 1000);
            } else {
                this.reset();
            }
        },
        continueOrdering() {
            this.countdown = this.initialCountdown;
            clearTimeout(this.countdownTimeout);
            this.resetIdleTimer();
            this.showDialog = false;
        },
        reset() {
            this.showDialog = false;
            this.countdown = this.initialCountdown;
            clearTimeout(this.countdownTimeout);
            this.$emit('restart');
        },
        updateRemainingTime() {
            const elapsed = (Date.now() - this.startTime) / 1000;
            this.remainingTime = Math.max(Math.ceil(this.idleTimeoutSeconds - elapsed), 0);
            if (this.remainingTime <= 0) {
                clearInterval(this.updateInterval);
            }
        },
    },
    computed: {
        adjustedTimeout() {
            return this.idleTimeoutSeconds * 1000;
        },
        progressBarValue() {
            return Math.min((this.countdown / this.initialCountdown) * 100, 100);
        },
        progressOffset() {
            const circumference = 2 * Math.PI * 10;
            const progress = this.remainingTime / this.idleTimeoutSeconds;
            return circumference * (1 - progress);
        },
    },
    mounted() {
        this.resetIdleTimer();
        eventBus.on('barcode-scanned', this.resetIdleTimer); // Listen for barcode scan event
        window.addEventListener('mousemove', this.resetIdleTimer);
        window.addEventListener('keypress', this.resetIdleTimer);
        window.addEventListener('touchstart', this.resetIdleTimer); // Add touch event listener
    },
    unmounted() {
        window.removeEventListener('mousemove', this.resetIdleTimer);
        window.removeEventListener('keypress', this.resetIdleTimer);
        eventBus.off('barcode-scanned', this.resetIdleTimer); // Remove barcode scan event listener
        window.removeEventListener('touchstart', this.resetIdleTimer); // Remove touch event listener

        clearTimeout(this.idleTimeout);
        clearTimeout(this.countdownTimeout);
        clearInterval(this.updateInterval);
    },
    watch: {
        $route() {
            this.resetIdleTimer();
        },
        timeoutActive(newVal) {
            if (newVal) {
                this.resetIdleTimer();
            } else {
                clearTimeout(this.idleTimeout);
                clearTimeout(this.countdownTimeout);
                clearInterval(this.updateInterval);
                this.showDialog = false;
                this.remainingTime = 0;
            }
        },
    },
};
</script>

<style scoped>
.countdown-progress {
    width: 100%;
    height: 1rem;
    border-radius: var(--border-radius-xs);
    overflow: hidden;
    background-color: #eee;
}

.countdown-progress::-webkit-progress-bar {
    background-color: #eee;
}

.countdown-progress::-webkit-progress-value {
    background-color: var(--brand-color);
    border-radius: var(--border-radius-xs);
    transition: width 0.5s ease;
}

.countdown-progress::-moz-progress-bar {
    background-color: var(--brand-color);
    border-radius: var(--border-radius-xs);
    transition: width 0.5s ease;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-9-overlay);
}

.idle-dialog {
    padding: 3.75rem;
    font-size: 2rem;
    background: var(--primary-white);
    border-radius: var(--border-radius-lg);
    position: fixed;
    top: 50%;
    left: 50%;
    background: var(--primary-white);
    transform: translate(-50%, -50%);
    z-index: var(--z-index-9);
}

.seconds {
    font-weight: 700;
    color: var(--brand-color);
}

.idle-dialog-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}

button {
    border: none;
    cursor: pointer;
    background-color: var(--brand-color);
    color: var(--primary-white);
    padding: 1rem 2rem;
    border-radius: var(--border-radius-xxl);
    font-size: 2rem;
    margin-left: 1rem;
}

button.cancel {
    background-color: var(--primary-white);
    color: var(--brand-color);
    border: 0.125rem solid var(--brand-color);
}

.idle-countdown {
    display: none;
    color: var(--brand-color);
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 9999;
    width: 0.25rem;
    height: 0.25rem;
}

.countdown-ring {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.countdown-ring-bg {
    fill: none;
    stroke: #e0e0e0;
    stroke-width: 2;
}

.countdown-ring-progress {
    fill: none;
    stroke: var(--brand-color);
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 62.83; /* 2 * π * 10 */
    transition: stroke-dashoffset 0.5s ease;
}
</style>
