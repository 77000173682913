<template>
    <div class="logo-container" v-if="logo" :aria-label="name" role="img" :class="{ square: shape == 'square' }">
        <div class="logo-background" :class="{ 'disabled': disabled }">
            <img :src="logo" alt="" class="logo-image" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LocationLogo',
    props: {
        logo: {
            type: String,
            default: ''
        },
        shape: {
            type: String,
            default: 'circle'
        },
        name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    components: {

    }
};
</script>

<style scoped>
.logo-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 0.125rem solid var(--grey-focus);
    background-color: #fff;
}

.logo-container.square {
    border-radius: 1rem;
}

.logo-background {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-background.disabled {
    filter: grayscale(100%);
}

.logo-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
</style>
