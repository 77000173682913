<template>
    <div class="slideshow-settings">

        <!-- Draggable Preview using actual IdleScreen -->
        <Teleport to="body">
            <div v-if="isPreviewActive" ref="previewWindow" class="preview-container" :style="previewPosition">
                <div class="drag-handle-top" @mousedown="startDrag"></div>
                <button class="close-btn" @click="togglePreview" aria-label="Close Preview">×</button>
                <div class="preview-content">
                    <IdleScreen :orderStarted="false" :enabled="true" :orderTypes="[]" :previewMode="true"
                        :backgroundType="'slideshow'" :slideshowImages="images" :slideshowInterval="rotationSpeed"
                        :transitionEffect="config?.screensaverTransition || 'fade'"
                        :showText="config?.screensaverShowText" :startOrderText="config?.text?.startOrder"
                        :startOrderSubtext="config?.text?.startOrderSubtext" :config="config" :idleTimeSeconds="0"
                        :shouldSync="false" class="preview-idle-screen" />
                </div>
                <div class="resize-handle" @mousedown.stop="startResize"></div>
            </div>
        </Teleport>

        <!-- Upload Section / Progress -->
        <div class="upload-container mb-4">
            <label v-if="!isUploading" for="slideshowImageUpload" class="upload-zone">
                <div class="upload-content">
                    <i class="fas fa-cloud-upload-alt mb-2"></i>
                    <span class="upload-text">Drag images here or click to upload</span>
                    <small class="upload-hint">Max 5MB per image · JPEG, PNG, GIF, BMP</small>
                </div>
                <input type="file" class="file-input" id="slideshowImageUpload" @change="handleSlideshowImageUpload"
                    accept="image/*" multiple>
            </label>

            <div v-else class="upload-progress">
                <div class="upload-content">
                    <div class="progress mb-3 w-75">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                            style="width: 100%"></div>
                    </div>
                    <p class="text-muted mb-0">Uploading your images...</p>
                </div>
            </div>
        </div>

        <!-- Settings Control -->
        <div class="settings-control mb-4">
            <label class="form-label d-flex justify-content-between align-items-center">
                <span>Rotation Speed (seconds)</span>
                <span class="text-muted small">{{ rotationSpeed }} sec</span>
            </label>
            <input type="range" class="form-range" v-model.number="rotationSpeed" min="2" max="20" step="1"
                @change="updateRotationSpeed">
        </div>
        <div class="preview-controls mb-4">
            <button type="button" class="btn btn-outline-primary" @click="togglePreview">
                <i class="fas" :class="isPreviewActive ? 'fa-eye-slash' : 'fa-eye'"></i>
                {{ isPreviewActive ? 'Hide Preview' : 'Show Preview' }}
            </button>
        </div>
        <!-- Transition Effect and Sync Control -->
        <div v-if="config.screensaverEnabled && config.screensaverBackgroundType === 'slideshow'" class="mb-3">
            <label for="screensaverTransition" class="form-label">Slideshow Transition Effect</label>
            <select class="form-select" :value="config.screensaverTransition" @change="updateTransition($event.target.value)"
                id="screensaverTransition">
                <option value="fade">Smooth Fade</option>
                <option value="ken-burns">Ken Burns Effect</option>
                <option value="glitch">Glitch</option>
                <option value="flip">Flip</option>
                <option value="shockwave">Shockwave</option>
                <option value="prism">Prism</option>
                <option value="kaleidoscope">Kaleidoscope</option>
                <option value="matrix">Matrix</option>
                <option value="ripple">Ripple</option>
            </select>
            <small class="form-text text-muted">
                Choose how images transition from one to another
            </small>

            <div class="form-check form-switch mt-3">
                <input class="form-check-input" type="checkbox" :checked="config.screensaverSync" @change="updateSync($event.target.checked)"
                    id="screensaverSync">
                <label class="form-check-label" for="screensaverSync"
                    v-tooltip="'Synchronize slideshow timing across all terminals'">
                    Sync Across Terminals
                </label>
                <small class="form-text text-muted d-block">
                    When enabled, slideshow transitions will be synchronized across all terminals
                </small>
            </div>
        </div>

        <!-- Image Gallery -->
        <div class="image-gallery">
            <draggable v-model="images" @end="updateImageOrder" class="gallery-grid" handle=".drag-handle"
                :item-key="'url'">
                <template #item="{ element, index }">
                    <div class="gallery-item">
                        <div class="image-card">
                            <div class="drag-handle" :class="{ 'is-dragging': isDragging }">
                                <span class="number" :class="{ 'number-update': element.isUpdating }">
                                    {{ isDragging ? '?' : index + 1 }}
                                </span>
                            </div>
                            <img :src="element.url" :alt="`Slideshow image ${index + 1}`" class="gallery-image" />
                            <button type="button" class="remove-btn" @click="removeImage(index)" title="Remove image">
                                <SvgIcon name="delete" />
                            </button>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
    <br />
</template>

<script>
import draggable from 'vuedraggable';
import IdleScreen from '../../features/IdleScreen.vue';

export default {
    components: {
        draggable,
        IdleScreen
    },
    props: {
        config: {
            type: Object,
            required: true
        }
    },
    emits: ['update', 'validation'],
    data() {
        return {
            images: [...(this.config.screensaverImages || [])].map(img => ({ ...img, isUpdating: false })),
            rotationSpeed: this.config.screensaverRotationSpeed || 5,
            isUploading: false,
            isDragging: false,
            dragStartIndex: null,
            isPreviewActive: false,
            previewPosition: {
                left: '20px',
                top: '20px'
            },
            dragOffset: { x: 0, y: 0 },
            currentImageIndex: 0,
            previewInterval: null,
            isResizing: false,
            resizeStartX: 0,
            resizeStartY: 0,
            startWidth: 0,
            startHeight: 0
        };
    },
    methods: {
        async handleSlideshowImageUpload(event) {
            const files = event.target.files;
            this.isUploading = true;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.size > 5 * 1024 * 1024) {
                    alert('File size exceeds the 5MB limit. Please choose a smaller file.');
                    continue;
                }
                try {
                    const response = await this.uploadFile(file);
                    this.images.push({
                        url: response.url,
                        duration: this.rotationSpeed // Convert to milliseconds
                    });
                    this.emitConfigUpdate();
                } catch (error) {
                    alert('Failed to upload image. Please try again.');
                }
            }
            this.isUploading = false;
        },
        async uploadFile(file) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                const response = await this.$store.dispatch('admin/uploadLogo', formData);
                return response;
            } catch (error) {
                console.error('Error uploading file:', error);
                throw error;
            }
        },
        removeImage(index) {
            this.images.splice(index, 1);
            this.emitConfigUpdate();
        },
        updateImageOrder(event) {
            const newIndex = event.newIndex;
            const oldIndex = event.oldIndex;

            // Mark items that need to be animated
            this.images.forEach((img, index) => {
                if (oldIndex < newIndex) {
                    if (index >= oldIndex && index <= newIndex) {
                        img.isUpdating = true;
                    }
                } else {
                    if (index >= newIndex && index <= oldIndex) {
                        img.isUpdating = true;
                    }
                }
            });

            // Remove animation class after transition
            setTimeout(() => {
                this.images.forEach(img => {
                    img.isUpdating = false;
                });
            }, 300);

            this.emitConfigUpdate();
            this.isDragging = false;
        },
        updateRotationSpeed() {
            this.images = this.images.map(img => ({
                ...img,
                duration: this.rotationSpeed // Convert to milliseconds
            }));

            this.emitConfigUpdate();
        },
        togglePreview() {
            this.isPreviewActive = !this.isPreviewActive;
            if (this.isPreviewActive) {
                this.startPreviewRotation();
            } else {
                this.stopPreviewRotation();
            }
        },
        startPreviewRotation() {
            this.currentImageIndex = 0;
            this.previewInterval = setInterval(() => {
                this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
            }, this.rotationSpeed * 1000);
        },
        stopPreviewRotation() {
            if (this.previewInterval) {
                clearInterval(this.previewInterval);
                this.previewInterval = null;
            }
        },
        startDrag(event) {
            if (event.target.closest('.close-btn')) return;

            this.isDragging = true;
            const rect = this.$refs.previewWindow.getBoundingClientRect();
            this.dragOffset = {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top
            };

            document.addEventListener('mousemove', this.handleDrag);
            document.addEventListener('mouseup', this.stopDrag);
        },
        handleDrag(event) {
            if (!this.isDragging) return;

            this.previewPosition = {
                left: `${event.clientX - this.dragOffset.x}px`,
                top: `${event.clientY - this.dragOffset.y}px`
            };
        },
        stopDrag() {
            this.isDragging = false;
            document.removeEventListener('mousemove', this.handleDrag);
            document.removeEventListener('mouseup', this.stopDrag);
        },
        emitConfigUpdate() {
            this.$emit('update', {
                ...this.config,
                screensaverImages: this.images,
                screensaverRotationSpeed: this.rotationSpeed,
            });
        },
        startResize(event) {
            event.preventDefault();
            event.stopPropagation();
            this.isResizing = true;
            this.resizeStartX = event.clientX;
            this.resizeStartY = event.clientY;
            this.startWidth = this.$refs.previewWindow.offsetWidth;
            this.startHeight = this.$refs.previewWindow.offsetHeight;

            document.addEventListener('mousemove', this.handleResize);
            document.addEventListener('mouseup', this.stopResize);
        },
        handleResize(event) {
            if (!this.isResizing) return;

            const minWidth = 320;
            const maxWidth = window.innerWidth * 0.8;
            
            let width = Math.min(maxWidth, Math.max(minWidth, 
                this.startWidth + (event.clientX - this.resizeStartX)));
            
            let height = Math.round(width * (9/16));

            this.$refs.previewWindow.style.width = `${width}px`;
            this.$refs.previewWindow.style.height = `${height}px`;
        },
        stopResize() {
            this.isResizing = false;
            document.removeEventListener('mousemove', this.handleResize);
            document.removeEventListener('mouseup', this.stopResize);
        },
        updateTransition(value) {
            this.$emit('update', {
                ...this.config,
                screensaverTransition: value
            });
        },
        updateSync(value) {
            this.$emit('update', {
                ...this.config,
                screensaverSync: value
            });
        }
    },
    computed: {
        hasImages() {
            return this.images && this.images.length > 0;
        },
        currentPreviewImage() {
            return this.images[this.currentImageIndex]?.url
        }
    },
    watch: {
        hasImages: {
            immediate: true,
            handler(hasImages) {
                this.$emit('validation', hasImages);
            }
        },
        'draggable:start'() {
            this.isDragging = true;
        },
        config: {
            handler(newConfig) {
                console.log('Config changed:', newConfig);
            },
            deep: true
        }
    },
    beforeUnmount() {
        this.stopPreviewRotation();
    }
};
</script>

<style scoped>

.preview-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.upload-container {
    min-height: 160px;
    border: 2px dashed #dee2e6;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.upload-zone,
.upload-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: inherit;
}

.upload-progress {
    background-color: #f8f9fa;
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-content i {
    font-size: 2rem;
    color: #6c757d;
}

.upload-text {
    font-size: 1.1rem;
    color: #495057;
    margin: 0.5rem 0;
}

.upload-hint {
    color: #6c757d;
}

.file-input {
    display: none;
}

.settings-control {
    margin-bottom: 20px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    padding: 1rem 0;
}

.gallery-item {
    position: relative;
}

.image-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.image-card:hover {
    transform: translateY(-2px);
}

.gallery-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
}

.drag-handle {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 28px;
    height: 28px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    opacity: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drag-handle .number {
    font-weight: 600;
    color: #2c3e50;
    font-size: 0.9rem;
    transition: transform 0.3s ease, color 0.3s ease;
}

.number-update {
    animation: numberUpdate 0.3s ease-in-out;
}

@keyframes numberUpdate {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(-10px);
        opacity: 0.5;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.drag-handle.is-dragging {
    background: #007bff;
    transform: scale(1.1);
}

.drag-handle.is-dragging .number {
    color: white;
    transform: scale(1.2);
}

.image-card:hover .drag-handle {
    opacity: 1;
}

.remove-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    color: #dc3545;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.image-card:hover .remove-btn {
    opacity: 1;
}

.remove-btn:hover {
    background: #dc3545;
    color: white;
}

.preview-container {
    position: fixed;
    width: 600px;
    height: 337.5px;
    background: #000;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 9999;
    overflow: hidden;
    cursor: move;
    resize: none;
    user-select: none;
    -webkit-user-select: none;
}

.drag-handle-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0 0 4px 4px;
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.close-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    font-size: 1.5rem;
    line-height: 1;
    padding: 4px 12px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s ease;
    z-index: 2;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
}

.close-btn:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.3);
}

.preview-content {
    position: relative;
    height: 100%;
    overflow: hidden;
    background: #000;
    cursor: default;
    z-index: 0;
    user-select: none;
    -webkit-user-select: none;
}

.preview-content :deep(*) {
    user-select: none !important;
    -webkit-user-select: none !important;
}

.preview-idle-screen {
    transform-origin: top left;
    width: 100% !important;
    height: 100% !important;
}

.preview-idle-screen :deep(.screensaver) {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
}

.preview-idle-screen :deep(.order-here) {
    font-size: 3rem !important;
    line-height: 3rem !important;
}

.preview-idle-screen :deep(.tap-screen) {
    font-size: 1rem !important;
    padding-top: 0.5rem !important;
}

.preview-idle-screen :deep(.screensaver-logo) {
    width: 3rem !important;
    height: 3rem !important;
    margin-bottom: 0.5rem !important;
}

.transition-fade {
    transition: opacity 0.5s ease;
}

.transition-ken-burns {
    animation: kenBurns 10s ease infinite;
}

@keyframes kenBurns {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.resize-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: se-resize;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
